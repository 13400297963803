
export const colors = new Map([
    //["pink", {label: 'Pink', background: 'bg-pink-500', text: 'text-pink-500', ring: 'ring-pink-500', focusRing: 'focus-within:ring-pink-500'}],
    //["rose", {label: 'Rose', background: 'bg-rose-500', text: 'text-rose-500', ring: 'ring-rose-500', focusRing: 'focus-within:ring-rose-500'}],
    ["red", {label: 'Red', background: 'bg-red-500', text: 'text-red-500', ring: 'ring-red-500', focusRing: 'focus-within:ring-red-500'}],
    ["orange", {label: 'Orange', background: 'bg-orange-500', text: 'text-orange-500', ring: 'ring-orange-500', focusRing: 'focus-within:ring-orange-500'}],
    //["amber", {label: 'Amber', background: 'bg-amber-500', text: 'text-amber-500', ring: 'ring-amber-500', focusRing: 'focus-within:ring-amber-500'}],
    ["yellow", {label: 'Yellow', background: 'bg-yellow-400', text: 'text-yellow-400', ring: 'ring-yellow-400', focusRing: 'focus-within:ring-yellow-400'}],
    //["lime", {label: 'Lime', background: 'bg-lime-500', text: 'text-lime-500', ring: 'ring-lime-500', focusRing: 'focus-within:ring-lime-500'}],
    ["green", {label: 'Green', background: 'bg-green-500', text: 'text-green-500', ring: 'ring-green-500', focusRing: 'focus-within:ring-green-500'}],
    //["emerald", {label: 'Emerald', background: 'bg-emerald-500', text: 'text-emerald-500', ring: 'ring-emerald-500', focusRing: 'focus-within:ring-emerald-500'}],
    //["teal", {label: 'Teal', background: 'bg-teal-500', text: 'text-teal-500', ring: 'ring-teal-500', focusRing: 'focus-within:ring-teal-500'}],
    //["cyan", {label: 'Cyan', background: 'bg-cyan-500', text: 'text-cyan-500', ring: 'ring-cyan-500', focusRing: 'focus-within:ring-cyan-500'}],
    //["sky", {label: 'Sky', background: 'bg-sky-500', text: 'text-sky-500', ring: 'ring-sky-500', focusRing: 'focus-within:ring-sky-500'}],
    ["blue", {label: 'Blue', background: 'bg-blue-500', text: 'text-blue-500', ring: 'ring-blue-500', focusRing: 'focus-within:ring-blue-500'}],
    ["indigo", {label: 'Indigo', background: 'bg-indigo-600', text: 'text-indigo-600', ring: 'ring-indigo-500', focusRing: 'focus-within:ring-indigo-600'}],
    ["violet", {label: 'Violet', background: 'bg-violet-500', text: 'text-violet-500', ring: 'ring-violet-500', focusRing: 'focus-within:ring-violet-500'}],
    //["purple", {label: 'Purple', background: 'bg-purple-500', text: 'text-purple-500', ring: 'ring-purple-500', focusRing: 'focus-within:ring-purple-500'}],
    //["fuchsia", {label: 'Fuchsia', background: 'bg-fuchsia-500', text: 'text-fuchsia-500', ring: 'ring-fuchsia-500', focusRing: 'focus-within:ring-fuchsia-500'}],
    //["gray", {label: 'Gray', background: 'bg-gray-500', text: 'text-gray-500', ring: 'ring-gray-500', focusRing: 'focus-within:ring-gray-500'}],
    //["black", {label: 'Black', background: 'bg-black', text: 'text-black', focusRing: 'focus-within:ring-black'}],
]);

export const prefixColor = (prefix, color) => {
    return `${prefix}-${color}`;
}
