<template>
  <div class="bg-gray-50 dark:bg-gray-900 font-light">
    <div class="relative overflow-hidden">
      <div class="relative pt-6 pb-16 sm:pb-24">
        <Popover>
          <div class="max-w-7xl mx-auto px-4 sm:px-6">
            <nav class="relative flex items-center justify-between sm:h-10 md:justify-center" aria-label="Global">
              <div class="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                <div class="flex items-center justify-between w-full md:w-auto">
                  <a href="https://interimapi.com">
                    <span class="sr-only">InterimAPI</span>
                    <img class="h-auto w-36 sm:h-10 inline dark:hidden" src="../assets/img/interim-logo.svg" alt="InterimAPI" />
                    <img class="h-auto w-36 sm:h-10 hidden dark:inline" src="../assets/img/interim-logo-dark.svg" alt="InterimAPI" />
                  </a>
                  <div class="-mr-2 flex items-center md:hidden">
                    <PopoverButton class="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                      <span class="sr-only">Open main menu</span>
                      <MenuIcon class="h-6 w-6" aria-hidden="true" />
                    </PopoverButton>
                  </div>
                </div>
              </div>
              <div class="hidden md:flex md:space-x-10">
                <template v-for="item in navigation" :key="item.name">
                  <a :href="item.href" :target="item.target" class="font-medium text-gray-500 dark:text-blue-200 hover:text-gray-900 dark:hover:text-white">{{ item.name }}</a>
<!--                  <button :key="item.name" v-if="item.name === `What's New`" :data-canny-changelog="item.name === 'Changelog'" type="button" class="font-medium text-gray-500 dark:text-blue-200 hover:text-gray-900 hover:text-white">{{ item.name }}</button>-->
                </template>
              </div>
              <div class="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
                <span class="inline-flex rounded-md shadow">
                  <a href="https://app.interimapi.com/login" class="inline-flex items-center px-4 py-2 border border-gray-50 dark:border-blue-600 text-base font-medium rounded-md text-blue-600 hover:text-blue-500"> Sign in </a>
                </span>
                <span class="ml-2 inline-flex rounded-md shadow">
                  <a href="https://app.interimapi.com/register" class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"> Start Free </a>
                </span>
              </div>
            </nav>
          </div>

          <transition enter-active-class="duration-150 ease-out" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
            <PopoverPanel focus class="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
              <div class="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div class="px-5 pt-4 flex items-center justify-between">
                  <div>
                    <img class="h-auto w-32" src="../assets/img/interim-logo.svg" alt="" />
                  </div>
                  <div class="-mr-2">
                    <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                      <span class="sr-only">Close main menu</span>
                      <XIcon class="h-6 w-6" aria-hidden="true" />
                    </PopoverButton>
                  </div>
                </div>
                <div class="px-2 pt-2 pb-3 space-y-1">
                  <a v-for="item in navigation" :key="item.name" :href="item.href" :target="item.target" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">{{ item.name }}</a>
                  <a href="https://app.interimapi.com/login" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Sign in</a>
                </div>
                <a href="https://app.interimapi.com/register" class="block w-full px-5 py-3 text-center font-medium text-white bg-blue-600 hover:bg-blue-700"> Start Free </a>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>

        <div class="mt-16 mx-auto max-w-7xl px-8 sm:mt-24 sm:px-6">
          <div class="md:text-center">
            <h1 class="text-blue-600 text-5xl font-extrabold text-gray-900 md:text-5xl lg:text-6xl">
              <span class="leading-tight">Create custom API endpoints with  <br class="hidden lg:inline-block">realistic fake data in seconds</span>
            </h1>
            <p class="mt-3 max-w-lg mx-auto text-lg text-gray-500 dark:text-blue-200 sm:text-xl md:mt-5 md:text-xl sm:max-w-4xl">
              Start designing, building, and testing your data-driven applications faster than ever without needing to have a backend.
              Get an instant database populated with fake data the way you need it and a powerful REST API that's ready to go out-of-the-box
            </p>
          </div>
        </div>
      </div>

      <div class="relative">
        <div class="absolute inset-0 flex flex-col" aria-hidden="true">
          <div class="flex-1" />
          <div class="flex-1 w-full bg-blue-600" />
        </div>
        <div class="max-w-7xl mx-auto px-4 sm:px-6">
          <div class="shadow-lg">
            <ImgComparisonSlider class="slider rounded-lg" value="50">
              <img
                  slot="first"
                  style="width: 100%"
                  width="3840" height="1939"
                  src="../assets/img/interim-api-view.png"
                  alt="API view"
              />
              <img
                  slot="second"
                  style="width: 100%"
                  width="3840" height="1939"
                  src="../assets/img/interim-data-view.png"
                  alt="Data view"
              />
              <svg slot="handle" class="custom-animated-handle" xmlns="http://www.w3.org/2000/svg" width="100" viewBox="-8 -3 16 6">
                <path class="fill-blue-600" d="M -5 -2 L -7 0 L -5 2 M -5 -2 L -5 2 M 5 -2 L 7 0 L 5 2 M 5 -2 L 5 2" stroke-width="1" vector-effect="non-scaling-stroke"></path>
              </svg>
            </ImgComparisonSlider>
          </div>
<!--          <img class="relative rounded-lg shadow-lg" width="1232" height="693" loading="eager" src="../assets/img/interim-endpoint.png" alt="Endpoint view" v-show="preview === 'endpoint'" />-->
<!--          <img class="relative rounded-lg shadow-lg" width="1232" height="693" loading="eager" src="../assets/img/interim-database.png" alt="Database view" v-show="preview === 'database'" />-->
        </div>
      </div>
    </div>

    <div class="bg-blue-600">
      <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <h2 class="text-center text-blue-200 text-sm font-semibold uppercase tracking-wide">Designers, Developers, and Product <br class="sm:hidden">Managers Love Interim</h2>
        <div class="mt-10 grid grid-cols-1 gap-10 md:grid-cols-3">
          <div class="col-span-1 flex">
            <div>
              <img class="h-12 rounded-full border border-2 border-white" src="../assets/img/avatars/allen.png" alt="Casey" />
              <div class="text-center text-xs font-light mt-2 text-white">Allen</div>
            </div>

            <div class="ml-4 bg-white dark:bg-blue-700 rounded-lg px-6 py-4 flex-1 drop-shadow-lg shadow-blue-900">
              <span class="text-blue-900 dark:text-blue-200 text-sm">InterimAPI is a game changer. I love it! It's never been easier to test a frontend and bootstrap a backend</span>
            </div>
          </div>
          <div class="col-span-1 flex">
            <div>
              <img class="h-12 rounded-full border border-2 border-white" src="../assets/img/avatars/georges.png" alt="Georges" />
              <div class="text-center text-xs font-light mt-2 text-white">Georges</div>
            </div>

            <div class="ml-4 bg-white dark:bg-blue-700 rounded-lg px-6 py-4 flex-1 drop-shadow-lg shadow-blue-900">
              <span class="text-blue-900 dark:text-blue-200 text-sm">This is such a cool service! I use it all the time building my apps in Draftbit</span>
            </div>
          </div>
          <div class="col-span-1 flex">
            <div>
              <img class="h-12 rounded-full border border-2 border-white" src="../assets/img/avatars/shawn.png" alt="Jessica" />
              <div class="text-center text-xs font-light mt-2 text-white">Shawn</div>
            </div>

            <div class="ml-4 bg-white dark:bg-blue-700 rounded-lg px-6 py-4 flex-1 drop-shadow-lg shadow-blue-900">
              <span class="text-blue-900 dark:text-blue-200 text-sm">Super handy...InterimAPI is my 'go to' to mock out data with a shape that will work for the real API</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { MenuIcon, XIcon } from '@heroicons/vue/outline';
import { ImgComparisonSlider } from '@img-comparison-slider/vue';

const navigation = [
  { name: 'Features', href: '#features', target: '' },
  { name: 'Pricing', href: '#pricing', target: '' },
  { name: 'FAQs', href: '#faqs', target: '' },
  //{ name: 'Community', href: 'https://community.interimapi.com', target: '_blank' },
  //{ name: 'Documentation', href: 'https://docs.interimapi.com', target: '_blank' },
  //{ name: `What's New`, href: 'https://roadmap.interimapi.com/changelog', target: '_blank' },
];



export default {
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    MenuIcon,
    XIcon,
    ImgComparisonSlider,
  },
  name: "Hero",
  data() {
    return {
      navigation: navigation,
      preview: 'endpoint',
    }
  },
  methods: {
    togglePreview() {
      this.preview === 'endpoint' ? this.preview = 'database' : this.preview = 'endpoint';
    }
  }
}
</script>

<style>
  .slider {
    --divider-color: rgb(255, 255, 255, 0);
    --default-handle-color: rgb(37, 99, 235);
    --divider-width: 6px;
    /*--default-handle-shadow: 0px 0px 5px rgba(0, 0, 0, 1);*/
    --divider-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.10);
    --default-handle-width: 100px;
  }

  .custom-animated-handle {
    cursor: move;
    transition: transform 0.2s;
  }

  .slider:hover .custom-animated-handle {
    transform: scale(1.2);
  }
</style>