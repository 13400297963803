<template>
  <div id="features" class="py-16 bg-gray-50 dark:bg-gray-900 overflow-hidden lg:py-24 font-light">
    <div class="relative max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">

      <div class="relative">
        <h2 class="sm:text-center text-4xl font-extrabold text-gray-900 dark:text-blue-600 md:text-5xl">
          Design, build, and test your front-ends faster
        </h2>
        <p class="mt-4 max-w-3xl mx-auto sm:text-center text-lg sm:text-xl text-gray-500 dark:text-blue-200">
          Using data that looks real and in the format you need can help you work faster, make better design decisions,
          and build more efficiently.
        </p>
      </div>

      <div class="relative mt-12 lg:mt-24">
        <div class="relative">
<!--          <h3 class="text-xl font-extrabold text-gray-900 dark:text-gray-400 sm:text-2xl">Here's what you get...</h3>-->
<!--          <p class="mt-3 text-gray-500 font-normal"></p>-->

          <div class="space-y-16">
            <div class="mt-10 lg:grid lg:grid-cols-2 lg:gap-12 lg:items-center">
              <div class="relative">
                <div>
                  <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                    <AnnotationIcon aria-hidden="true" class="h-6 w-6"/>
                  </div>
                  <p class="ml-16 text-lg leading-6 font-medium text-gray-900 dark:text-white">Realistic Data</p>
                </div>
                <div class="mt-2 ml-16 text-base text-gray-500 dark:text-gray-300">
                  With <strong>{{ this.fakerCount() }}</strong> Data Generators at your fingertips - and more being
                  added over time -
                  you can easily generate database tables of fake data that's right for your project's needs. <a
                    class="text-blue-600" href="#generators">Explore Generators →</a>
                </div>
              </div>
              <div class="mt-8 lg:mt-0 p-6 bg-white dark:bg-black rounded-lg shadow-md">
                <div class="flex justify-between text-xs text-gray-400">
                  <span>Generator</span>
                  <span>Example Output</span>
                </div>
                <div class="mt-1 flex items-center">
                  <div>
                    <div
                        class="mt-1 px-2 py-1 bg-blue-50 dark:bg-blue-600 text-center text-blue-600 dark:text-blue-200 border border-blue-600 dark:border-blue-400 rounded-md text-sm">
                      Full Name
                    </div>
                  </div>
                  <div class="flex-1 border-b border-dashed border-gray-300 dark:border-gray-600"></div>
                  <div>
                    <div
                        class="mt-1 px-2 py-1 border border-dashed border-gray-300 dark:border-gray-600 rounded-md font-mono text-gray-600 dark:text-gray-300 text-sm">
                      Rachel Zulauf
                    </div>
                  </div>
                </div>
                <div class="flex items-center mt-2">
                  <div>
                    <div
                        class="px-2 py-1 bg-blue-50 dark:bg-blue-600 text-center text-blue-600 dark:text-blue-200 border border border-blue-600 dark:border-blue-400 rounded-md text-sm">
                      Email
                    </div>
                  </div>
                  <div class="flex-1 border-b border-dashed border-gray-300 dark:border-gray-600"></div>
                  <div>
                    <div
                        class="px-2 py-1 border border-dashed border-gray-300 dark:border-gray-600 rounded-md font-mono text-gray-600 dark:text-gray-300 text-sm">
                      zulauf.rachel@example.org
                    </div>
                  </div>
                </div>
                <div class="flex items-center mt-2">
                  <div>
                    <div
                        class="px-2 py-1 bg-blue-50 dark:bg-blue-600 text-center text-blue-600 dark:text-blue-200 border border border-blue-600 dark:border-blue-400 rounded-md text-sm">
                      Job Title
                    </div>
                  </div>
                  <div class="flex-1 border-b border-dashed border-gray-300 dark:border-gray-600"></div>
                  <div>
                    <div
                        class="px-2 py-1 border border-dashed border-gray-300 dark:border-gray-600 rounded-md font-mono text-gray-600 dark:text-gray-300 text-sm">
                      Cashier
                    </div>
                  </div>
                </div>
                <div class="flex items-center mt-2">
                  <div>
                    <div
                        class="px-2 py-1 bg-blue-50 dark:bg-blue-600 text-center text-blue-600 dark:text-blue-200 border border border-blue-600 dark:border-blue-400 rounded-md text-sm">
                      ISO8601
                    </div>
                  </div>
                  <div class="flex-1 border-b border-dashed border-gray-300 dark:border-gray-600"></div>
                  <div>
                    <div
                        class="px-2 py-1 border border-dashed border-gray-300 dark:border-gray-600 rounded-md font-mono text-gray-600 dark:text-gray-300 text-sm">
                      2015-10-05T13:43:19+0000
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-10 lg:grid lg:grid-cols-2 lg:gap-12 lg:items-center">
              <div class="relative">
                <div>
                  <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                    <SwitchHorizontalIcon aria-hidden="true" class="h-6 w-6"/>
                  </div>
                  <p class="ml-16 text-lg leading-6 font-medium text-gray-900 dark:text-white">Full REST API</p>
                </div>
                <div class="mt-2 ml-16 text-base text-gray-500 dark:text-gray-300">
                  Once your database tables are created, you'll have access to familiar REST operations like
                  <strong>GET
                    POST, PUT, and DELETE</strong> so you can interact with your data just like you're used to.
                </div>
              </div>
              <div class="mt-8 lg:mt-0 p-6 bg-white dark:bg-black rounded-lg shadow-md">
                <div class="py-1 pl-1 pr-3 border border-gray-100 dark:border-gray-800 rounded-lg flex items-center">
                  <div>
                    <div class="w-20 px-2 py-1.5 bg-blue-500 text-center text-white rounded-md text-sm">
                      GET
                    </div>
                  </div>
                  <div>
                    <div class="px-2 py-1 text-gray-600 dark:text-gray-300 font-mono text-sm hidden sm:block">
                      /people/{id?}
                    </div>
                  </div>
                  <div class="flex-1 text-right text-gray-500 dark:text-gray-400 text-sm">
                    Read record or records
                  </div>
                </div>
                <div class="py-1 pl-1 pr-3 border border-gray-100 dark:border-gray-800 rounded-lg flex items-center mt-1">
                  <div>
                    <div class="w-20 px-2 py-1.5 bg-green-500 text-center text-white rounded-md text-sm">
                      POST
                    </div>
                  </div>
                  <div>
                    <div class="px-2 py-1 text-gray-600 dark:text-gray-300 font-mono text-sm hidden sm:block">
                      /people
                    </div>
                  </div>
                  <div class="flex-1 text-right text-gray-500 dark:text-gray-400 text-sm">
                    Create record or records
                  </div>
                </div>
                <div class="py-1 pl-1 pr-3 border border-gray-100 dark:border-gray-800 rounded-lg flex items-center mt-1">
                  <div>
                    <div class="w-20 px-2 py-1.5 bg-orange-500 text-center text-white rounded-md text-sm">
                      PUT
                    </div>
                  </div>
                  <div>
                    <div class="px-2 py-1 text-gray-600 dark:text-gray-300 font-mono text-sm hidden sm:block">
                      /people/{id}
                    </div>
                  </div>
                  <div class="flex-1 text-right text-gray-500 dark:text-gray-400 text-sm">
                    Update a single record
                  </div>
                </div>
                <div class="py-1 pl-1 pr-3 border border-gray-100 dark:border-gray-800 rounded-lg flex items-center mt-1">
                  <div>
                    <div class="w-20 px-2 py-1.5 bg-red-500 text-center text-white rounded-md text-sm">
                      DELETE
                    </div>
                  </div>
                  <div>
                    <div class="px-2 py-1 text-gray-600 dark:text-gray-300 font-mono text-sm hidden sm:block">
                      /people/{id}
                    </div>
                  </div>
                  <div class="flex-1 text-right text-gray-500 dark:text-gray-400 text-sm">
                    Delete a single record
                  </div>
                </div>
              </div>

            </div>

            <div class="mt-10 lg:grid lg:grid-cols-2 lg:gap-12 lg:items-center">
              <div class="relative">
                <div>
                  <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                    <FilterIcon aria-hidden="true" class="h-6 w-6"/>
                  </div>
                  <p class="ml-16 text-lg leading-6 font-medium text-gray-900 dark:text-white">Powerful Querying</p>
                </div>
                <div class="mt-2 ml-16 text-base text-gray-500 dark:text-gray-300">
                  Fetch the data you need easily and intuitively using the powerful built-in query methods that allow
                  you to <strong>Filter, Sort, Group, Search, Paginate</strong> and more.
                </div>
              </div>
              <div class="mt-8 lg:mt-0 p-6 bg-white dark:bg-black rounded-lg shadow-md">
                <div class="flex items-baseline">
                  <!--                  <div>-->
                  <!--                    <div class="w-20 mt-1 px-2 py-1 bg-blue-600 text-center text-white rounded-md text-sm">-->
                  <!--                      FILTER-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <div>
                    <div class="text-gray-600 dark:text-gray-300">
                      <div class="text-sm font-medium text-blue-600">Filter records</div>
                      <!--                      <div class="text-sm">Get all users who last logged in before Jan 1, 2022 or haven't completed onboarding</div>-->

                      <div
                          class="mt-1 p-2 border border-gray-200 dark:border-gray-800 bg-gray-50 dark:bg-gray-900 rounded-md text-gray-500 dark:text-gray-300 text-xs font-mono break-all">
                        /people?where[age][btwn]=18,40&orWhere[has_children][eq]=false
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-baseline mt-3">
                  <!--                  <div>-->
                  <!--                    <div class="w-20 mt-1 px-2 py-1 bg-blue-600 text-center text-white rounded-md text-sm">-->
                  <!--                      Search-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <div>
                    <div class="text-gray-600 dark:text-gray-300">
                      <div class="text-sm font-medium text-blue-600">Sort & Group records</div>

                      <div
                          class="mt-1 p-2 border border-gray-200 dark:border-gray-800 bg-gray-50 dark:bg-gray-900 rounded-md text-gray-500 dark:text-gray-300 text-xs font-mono break-all">
                        /people?where[email][like]=@example.com&sort=name&group=position
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-center mt-3">
                  <!--                  <div>-->
                  <!--                    <div class="w-20 px-2 py-1 bg-blue-600 text-center text-white rounded-md text-sm">-->
                  <!--                      PUT-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <div>
                    <div class="text-gray-600 dark:text-gray-300">
                      <div class="text-sm font-medium text-blue-600">Paginate records</div>

                      <div
                          class="mt-1 p-2 border border-gray-200 dark:border-gray-800 bg-gray-50 dark:bg-gray-900 rounded-md text-gray-500 dark:text-gray-300 text-xs font-mono break-all">
                        /people?where[birthday][lt]=1980-01-01&paginate=20&page=2
                      </div>
                    </div>
                  </div>
                </div>


              </div>

            </div>

            <div class="mt-10 lg:grid lg:grid-cols-2 lg:gap-12 lg:items-center">
              <div class="relative">
                <div>
                  <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                    <DatabaseIcon aria-hidden="true" class="h-6 w-6"/>
                  </div>
                  <p class="ml-16 text-lg leading-6 font-medium text-gray-900 dark:text-white">SQL Databases</p>
                </div>
                <div class="mt-2 ml-16 text-base text-gray-500 dark:text-gray-300">
                  Workspaces get their own SQL database and you can run actions to <strong>regenerate and remove data</strong>, in addition to interacting via your Endpoint APIs
                </div>
              </div>
              <div class="mt-8 lg:mt-0 bg-white dark:bg-black rounded-lg shadow-md overflow-hidden">
                <div class="overflow-y-auto overflow-x-hidden max-h-52 rounded-lg overflow-hidden">
                  <div class="align-middle inline-block min-w-full">
                    <div class="">
                      <table class="min-w-full divide-y divide-gray-100 dark:divide-black table-auto">
                        <thead class="bg-blue-600 dark:bg-black">
                        <tr>
                          <th class="px-6 py-5 text-left text-xs font-medium text-blue-200 dark:text-gray-400 uppercase tracking-wider"
                              scope="col">id
                          </th>
                          <th class="px-6 py-4 text-left text-xs font-medium text-blue-200 dark:text-gray-400 uppercase tracking-wider"
                              scope="col">name
                          </th>
                          <th class="px-6 py-4 text-left text-xs font-medium text-blue-200 dark:text-gray-400 uppercase tracking-wider"
                              scope="col">email
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="bg-white dark:bg-gray-900">
                          <td class="px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                            <span>1</span></td>
                          <td class="px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                            <span>Melba</span></td>
                          <td class="px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                            <span>watson.rogahn@example.org</span></td>

                        </tr>
                        <tr class="bg-gray-50 dark:bg-black">
                          <td class="px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                            <span>2</span></td>
                          <td class="px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                            <span>Abby</span></td>
                          <td class="px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                            <span>mortimer.smith@example.org</span></td>

                        </tr>
                        <tr class="bg-white dark:bg-gray-900">
                          <td class="px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                            <span>3</span></td>
                          <td class="px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                            <span>Raheem</span></td>
                          <td class="px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                            <span>darlene74@example.org</span></td>
                        </tr>
                        <tr class="bg-gray-50 dark:bg-black">
                          <td class="px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                            <span>4</span></td>
                          <td class="px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                            <span>Herman</span></td>
                          <td class="px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                            <span>lueilwitz.lillian@example.org</span></td>
                        </tr>
                        <tr class="bg-white dark:bg-gray-900">
                          <td class="px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                            <span>5</span></td>
                          <td class="px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                            <span>Waino</span></td>
                          <td class="px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                            <span>kunde.princess@example.com</span></td>
                        </tr>
                        <tr class="bg-gray-50 dark:bg-black">
                          <td class="px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                            <span>6</span></td>
                          <td class="px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                            <span>Patsy</span></td>
                          <td class="px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                            <span>justice10@example.com</span></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {AnnotationIcon, DatabaseIcon, FilterIcon, MailIcon, SwitchHorizontalIcon} from '@heroicons/vue/outline'
import {fakers} from "@/helpers/Fakers";

export default {
  name: "Features",
  components: {
    AnnotationIcon, DatabaseIcon, FilterIcon, MailIcon, SwitchHorizontalIcon,
  },
  data() {
    return {
      primaryFeatures: [
        {
          id: 1,
          name: 'Realistic Data',
          description: `With <strong>${this.fakerCount()}</strong> data type generators at your fingertips - and more being added over time -
          you can easily generate fake data that's the right fit for your project's needs.`,
          icon: AnnotationIcon,
        },
        {
          id: 2,
          name: 'Full REST API',
          description: `Once your fake data has been generated, you'll have access to familiar REST operations like GET
          POST, PUT, and DELETE so you can interact with your data just like you're used to.`,
          icon: SwitchHorizontalIcon,
        },
        {
          id: 3,
          name: 'Powerful Querying',
          description: `Fetch the data you need easily and intuitively using the powerful built-in query methods that allow
          you to Filter, Sort, Group, Search, Paginate and more.`,
          icon: FilterIcon,
        },
        {
          id: 4,
          name: '',
          description:
              ``,
          icon: DatabaseIcon,
        },
      ],
      secondaryFeatures: [
        {
          id: 1,
          name: 'Real SQL Database',
          description:
              'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
          icon: DatabaseIcon,
        },
        {
          id: 2,
          name: 'Refresh, Truncate, Dump',
          description:
              'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
          icon: MailIcon,
        },
      ],
    }
  },
  methods: {
    typeCount() {
      return fakers.size;
    },
    fakerCount() {
      let count = 0;
      fakers.forEach((type) => {
        count += Object.keys(type).length;
      });
      return count;
    }
  }
}
</script>