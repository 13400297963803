<template>
  <div id="generators" class="bg-white dark:bg-black font-light">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
      <div class="max-w-3xl mx-auto sm:text-center">
        <h2 class="text-4xl font-extrabold text-gray-900 dark:text-blue-600 md:text-5xl">Lots of Data Generators</h2>
        <p class="mt-4 text-lg sm:text-xl text-gray-500 dark:text-blue-200">
          Data Generators are one of the unique features that makes InterimAPI so
          powerful. There's already many to choose from and more being added all the time.
        </p>
      </div>
      <dl class="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8">
        <div v-for="type in fakers.keys()" :key="type" class="relative">
          <dt>
            <CheckCircleIcon aria-hidden="true" class="absolute h-6 w-6 text-blue-500"/>
            <p class="ml-9 text-lg leading-6 font-medium text-gray-900 dark:text-white">{{ type }}</p>
          </dt>
          <dd class="mt-2 ml-9 text-base text-gray-500">
            <div class="flex flex-wrap gap-2">
              <button
                  type="button"
                  v-for="(faker, id) of fakers.get(type)"
                  class="px-2 py-1 text-sm bg-gray-50 dark:bg-blue-500 dark:bg-opacity-20 border border-gray-200 dark:border-blue-600 text-gray-400 dark:text-blue-500 hover:bg-blue-50 dark:hover:bg-blue-600 hover:text-blue-500 dark:hover:text-blue-200 hover:border-blue-500 dark:hover:border-blue-300 rounded cursor-pointer"
                  @click="showGeneratorsModal(type, faker, id)">
                {{ faker.label }}
              </button>
            </div>
          </dd>
        </div>
      </dl>
    </div>
  </div>

  <TransitionRoot as="template" :show="showingGeneratorsModal">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto font-light" @close="closeGeneratorsModal">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-16 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 dark:bg-black bg-opacity-75 dark:bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block w-full align-bottom bg-white dark:bg-black dark:border dark:border-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl">
            <div class="grid grid-cols-1 lg:grid-cols-3 divide-x divide-gray-100 dark:divide-gray-800">

              <div class="col-span-1 max-h-96 lg:h-96 order-2 lg:order-1 overflow-y-auto bg-gray-50 dark:bg-black">
                <nav id="generatorNav" ref="generatorNav" class="relative h-full overflow-y-auto">
                  <div v-for="fakerType in fakers.keys()" :key="fakerType" class="relative">

                    <!-- z-10 sticky top-0 -->
                    <div class="bg-gray-100 dark:bg-gray-800 px-3 py-3 text-sm font-medium text-lg text-gray-400">
                      <h3>{{ fakerType }}</h3>
                    </div>
                    <ul role="list" class="relative z-0 px-4 py-2">
                      <li v-for="(generator, id) in fakers.get(fakerType)" :key="generator" :ref="id" :id="id" class="pt-2">
                        <button :class="[generator === faker ? 'bg-blue-600 text-white' : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-900 hover:text-gray-900 dark:hover:text-gray-300', 'w-full rounded-md flex items-center px-3 py-2 text-sm rounded-md']"
                                type="button"
                                @click="scrollToDefinition(fakerType, generator, id)">
                          <span>
                            {{ generator.label }}
                          </span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

              <div class="col-span-2 max-h-96 lg:h-96 order-1 border-b border-gray-100 lg:border-b-0 dark:bg-gray-900">
                <div class="relative h-full overflow-y-auto py-5 px-5" id="definitions">
                  <div class="text-xl font-semibold dark:text-gray-200">
                    Data Generators
                  </div>
                  <div class="mt-2 text-gray-500 dark:text-gray-400 text-sm ">
                    When you add an attribute
                    to your endpoint, you need to assign it a type based on the kind of data
                    you want to associate with the attribute.
                  </div>
                  <div class="mt-2 text-gray-500 dark:text-gray-400 text-sm ">
                    Select an attribute from the list to see its definition along with an
                    example of the data it returns.
                  </div>

                  <div class="mt-6"  v-for="fakerType in fakers.keys()" :key="fakerType">
                    <h2 class="text-lg font-semibold text-gray-800 dark:text-gray-200 truncate">{{ fakerType }}</h2>
                    <hr class="border-gray-100 dark:border-gray-700 my-2"/>
                    <div class="py-3" v-for="(faker, id) in fakers.get(fakerType)" :id="`${id}_definition`">
                      <div class="flex items-center justify-between">
                        <div class="font-semibold text-blue-600 truncate">
                          {{ faker.label }}
                        </div>
                        <div class="ml-2 flex-shrink-0 flex">
                          <div class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 dark:bg-blue-600 text-blue-600 dark:text-blue-200">
                            {{ faker.type }}
                          </div>
                        </div>
                      </div>
                      <div class="mt-4">
                        <div>
                          <div class="text-sm text-gray-600 dark:text-gray-400">
                            {{ faker.description }}
                          </div>
                          <div class="break-words mt-4 px-3 py-2 text-sm bg-gray-50 dark:bg-gray-900 font-mono dark:text-gray-100 rounded-md dark:border dark:border-gray-800">
                            {{ faker.example }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {fakers} from "@/helpers/Fakers";
import {colors} from "@/helpers/Colors";
import {CheckCircleIcon} from '@heroicons/vue/outline';
import {Dialog, DialogOverlay, TransitionChild, TransitionRoot} from "@headlessui/vue";

export default {
  name: "Generators",
  components: {
    CheckCircleIcon,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    Dialog
  },
  data() {
    return {
      fakers: fakers,
      colors: colors,
      type: null,
      faker: null,
      showingGeneratorsModal: false,
    }
  },
  computed: {
    randomColor() {
      let min = Math.ceil(0);
      let max = Math.floor(colors.size);
      return Array.from(colors)[Math.floor(Math.random() * (max - min + 1) + min)][1].text;
    }
  },
  methods: {
    showGeneratorsModal(type, faker, id) {
      console.log(type, faker, id);
      this.type = type;
      this.faker = faker;
      this.showingGeneratorsModal = true;

      setTimeout(() => {
        //document.querySelector(`#${id}`).scrollIntoView();
        let generatorNavItem = document.getElementById(id);
        //document.getElementById('generatorNav').scrollTop = generatorNavItem.offsetTop;
        document.getElementById(id).scrollIntoView({behavior: 'smooth'});
        document.getElementById(`${id}_definition`).scrollIntoView({behavior: 'smooth'});
        //this.$refs.generatorNav.scrollTo(0, document.getElementById(id).offsetTop);
      }, 250)
    },

    closeGeneratorsModal() {
      this.showingGeneratorsModal = false;
      this.type = null;
      this.faker = null;
    },

    scrollToDefinition(type, generator, id) {
      this.type = type;
      this.faker = generator;
      document.getElementById(`${id}_definition`).scrollIntoView({behavior: 'smooth'});
    }
  }
}
</script>