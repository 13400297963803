<template>
  <main>
    <Hero/>

    <Features/>

    <Generators/>

    <Pricing/>

    <Faqs/>

    <FooterNav/>
  </main>
</template>

<script>
import Hero from "@/components/Hero";
import Features from "@/components/Features";
import Generators from "@/components/Generators";
import Pricing from "@/components/Pricing";
import Faqs from "@/components/Faqs";
import FooterNav from "@/components/FooterNav";
export default {
  name: 'App',
  components: {FooterNav, Faqs, Pricing, Generators, Features, Hero},
  mounted() {
    document.documentElement.className = 'bg-white dark:bg-black scroll-smooth';

    //document.documentElement.classList.add('bg-white dark:bg-black scroll-smooth');
    //document.body.setAttribute('class', 'bg-white dark:bg-black scroll-smooth')

    // Termly
    // let termly = document.createElement("script");
    // termly.src = 'https://app.termly.io/embed.min.js';
    // termly.setAttribute('data-auto-block', 'on');
    // termly.setAttribute('data-website-uuid', '26415b43-30fc-4ad0-b524-80c155994fd7');
    // document.head.prepend(termly);

    //Fathom analytics
    let fathom = document.createElement("script");
    fathom.src = "https://cdn.usefathom.com/script.js";
    fathom.setAttribute('data-site', 'WJRZOYCE');
    fathom.setAttribute('defer', '');
    document.head.append(fathom);

    // Canny
    // let canny = document.createElement("script");
    // canny.innerText = '!function(w,d,i,s){function l(){if(!d.getElementById(i)){var f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){var c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l):w.addEventListener("load",l,!1)}}(window,document,"canny-jssdk","script");';
    // canny.setAttribute('defer', '');
    // document.head.append(canny);
    // Canny('initChangelog', {
    //   appID: '6266643c0d0af452a6c90d20',
    //   position: 'bottom',
    //   align: 'right',
    // });
  },
  beforeDestroy() {
    //Canny('closeChangelog');
  }
}
</script>
