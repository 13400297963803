<template>
  <div id="pricing" class="bg-blue-600 font-light">
    <div class="pt-12 sm:pt-16 lg:pt-24">
      <div class="max-w-7xl mx-auto sm:text-center px-4 sm:px-6 lg:px-8">
        <div class="max-w-3xl mx-auto lg:max-w-none">
<!--          <p class="sm:text-center text-4xl font-extrabold text-white md:text-5xl">Simple straight-forward pricing</p>-->
          <p class="sm:text-center text-4xl font-extrabold text-white md:text-5xl">Simple straight-forward pricing</p>
          <p class="mt-4 max-w-3xl mx-auto sm:text-center text-lg sm:text-xl text-blue-200">Start for free then upgrade when you need more</p>

          <div class="relative mt-6 flex sm:justify-center sm:mt-8">
            <div class="bg-blue-700 p-0.5 rounded-lg flex">
              <button @click="pricing = 'monthly'" type="button" :class="[pricing === 'monthly' ? 'bg-white border-blue-700 shadow-sm text-blue-700 hover:bg-blue-50' : ' border border-transparent text-blue-200 hover:text-white', 'relative py-2 px-6 rounded-md text-sm font-medium whitespace-nowrap focus:outline-none focus:z-10']">Monthly billing</button>
              <button @click="pricing = 'yearly'" type="button" :class="[pricing === 'yearly' ? 'bg-white border-blue-700 shadow-sm text-blue-700 hover:bg-blue-50' : ' border border-transparent text-blue-200 hover:text-white', 'ml-0.5 relative py-2 px-6 rounded-md text-sm font-medium whitespace-nowrap focus:outline-none focus:z-10']">Yearly billing</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-8 pb-12 bg-gray-50 dark:bg-gray-900 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
      <div class="relative">
        <div class="absolute inset-0 h-3/4 bg-blue-600" />
        <div class="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-3 lg:gap-5 lg:space-y-0">
            <div v-for="tier in plans" :key="tier.name" class="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div class="px-6 py-8 bg-white dark:bg-gray-900 sm:p-10 sm:pb-6">
                <div>
                  <h3 class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-blue-100 dark:bg-blue-600 text-blue-600 dark:text-blue-300" id="tier-standard">
                    {{ tier.name }}
                  </h3>
                </div>
                <div class="mt-3 flex items-center">
                  <p class="text-gray-900 dark:text-blue-100 text-6xl font-extrabold tracking-tight">${{ pricing === 'monthly' ? tier.priceMonthly : tier.priceYearly }}</p>
                  <div class="ml-4">
                    <p class="text-gray-900 text-sm">USD / {{ pricing === 'monthly' ? 'mo' : 'yr'}}</p>
                    <p v-if="pricing === 'yearly' && tier.name !== 'Free'" :class="[tier.featured ? 'text-green-400' : 'text-green-400', 'text-xs']"><strong>2 months free</strong>!</p>
                  </div>
                </div>
<!--                <p class="mt-5 text-lg text-gray-500">-->
<!--                  {{ tier.description }}-->
<!--                </p>-->
              </div>
              <div class="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 dark:bg-black space-y-6 sm:p-10 sm:pt-6">
                <ul role="list" class="space-y-4">
                  <li v-for="feature in tier.features" :key="feature" class="flex items-start">
                    <div class="flex-shrink-0">
                      <CheckIcon class="h-6 w-6 text-green-500" aria-hidden="true" />
                    </div>
                    <p class="ml-3 text-base text-gray-700 dark:text-gray-300">
                      {{ feature }}
                    </p>
                  </li>
                </ul>
                <div class="rounded-md shadow">
                  <a :href="tier.href" @click="trackGoal(tier.event)" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700" aria-describedby="tier-standard">
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
        <div class="max-w-md mx-auto lg:max-w-5xl">
          <div class="rounded-lg bg-gray-100 dark:bg-black px-6 py-8 sm:p-10 lg:flex lg:items-center">
            <div class="flex-1">
              <div class="text-lg text-gray-600 dark:text-gray-400">Need something different? If these plans don't fit your needs, we can try to help</div>
            </div>
            <div class="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
              <a href="mailto:hello@interimapi.com" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"> Let us know </a>
            </div>
          </div>

          <!-- Feature comparison (up to lg) -->
          <section aria-labelledby="mobile-comparison-heading" class="lg:hidden">
            <h2 id="mobile-comparison-heading" class="sr-only">Feature comparison</h2>

            <div class="max-w-2xl mx-auto py-16 space-y-16">
              <div v-for="(plan, mobilePlanIndex) in plans" :key="mobilePlanIndex" class="border-t border-gray-200 dark:border-gray-800">
                <div :class="[plan.featured ? 'border-green-500' : 'border-transparent', '-mt-px pt-6 border-t-2 sm:w-1/2']">
                  <h3 :class="[plan.featured ? 'text-green-500' : 'text-gray-900 dark:text-gray-200', 'text-sm font-bold']">{{ plan.name }}</h3>
                  <p class="mt-2 text-sm text-gray-500 dark:text-gray-400">{{ plan.description }}</p>
                </div>
                <h4 class="mt-10 text-sm font-bold text-gray-900 dark:text-gray-300">Features</h4>

                <div class="mt-6 relative">
                  <!-- Fake card background -->
                  <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
                    <div :class="[plan.featured ? 'shadow-md' : 'shadow', 'absolute right-0 w-1/2 h-full bg-white dark:bg-gray-900 rounded-lg']" />
                  </div>

                  <div :class="[plan.featured ? 'ring-2 ring-green-500 shadow-md' : 'ring-1 ring-black ring-opacity-5 shadow', 'relative py-3 px-4 bg-white dark:bg-black rounded-lg sm:p-0 sm:bg-transparent sm:rounded-none sm:ring-0 sm:shadow-none']">
                    <dl class="divide-y divide-gray-200 dark:divide-gray-800">
                      <div v-for="feature in features" :key="feature.title" class="py-3 flex items-center justify-between sm:grid sm:grid-cols-2">
                        <dt class="pr-4 text-sm font-medium text-gray-600 dark:text-gray-400">{{ feature.title }}</dt>
                        <dd class="flex items-center justify-end sm:px-4 sm:justify-center">
                          <span v-if="typeof feature.tiers[mobilePlanIndex].value === 'string'" :class="[feature.tiers[mobilePlanIndex].featured ? 'text-green-500' : 'text-gray-900 dark:text-gray-300', 'text-sm font-medium']">{{ feature.tiers[mobilePlanIndex].value }}</span>
                          <template v-else>
                            <CheckIcon v-if="feature.tiers[mobilePlanIndex].value === true" class="mx-auto h-5 w-5 text-green-500" aria-hidden="true" />
                            <XIcon v-else class="mx-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                            <span class="sr-only">{{ feature.tiers[mobilePlanIndex].value === true ? 'Yes' : 'No' }}</span>
                          </template>
                        </dd>
                      </div>
                    </dl>
                  </div>

                  <!-- Fake card border -->
                  <div aria-hidden="true" class="hidden absolute inset-0 pointer-events-none sm:block">
                    <div :class="[plan.featured ? 'ring-2 ring-green-500' : 'ring-1 ring-black ring-opacity-5', 'absolute right-0 w-1/2 h-full rounded-lg']" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- Feature comparison (lg+) -->
          <section aria-labelledby="comparison-heading" class="hidden lg:block">
            <h2 id="comparison-heading" class="sr-only">Feature comparison</h2>

            <div class="max-w-7xl mx-auto py-16">
              <div class="w-full flex items-stretch">
                <div class="-mt-px w-1/4 py-6 pr-4 flex items-end">
                  <h3 class="mt-auto text-sm font-bold text-gray-900 dark:text-gray-400">Features</h3>
                </div>
                <div v-for="(plan, planIdx) in plans" :key="plan.title" aria-hidden="true" :class="[planIdx === plans.length - 1 ? '' : 'pr-4', '-mt-px pl-4 w-1/4']">
                  <div :class="[plan.featured ? 'border-green-500' : 'border-transparent', 'py-6 border-t-2']">
                    <p :class="[plan.featured ? 'text-green-500' : 'text-gray-900 dark:text-gray-200', 'text-sm font-bold']">{{ plan.name }}</p>
                    <p class="mt-2 text-sm text-gray-500 dark:text-gray-500">{{ plan.description }}</p>
                  </div>
                </div>
              </div>

              <div class="relative">
                <!-- Fake card backgrounds -->
                <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                  <div class="w-1/4 pr-4" />
                  <div class="w-1/4 px-4">
                    <div class="w-full h-full bg-white dark:bg-black rounded-lg shadow" />
                  </div>
                  <div class="w-1/4 px-4">
                    <div class="w-full h-full bg-white dark:bg-black rounded-lg shadow" />
                  </div>
                  <div class="w-1/4 pl-4">
                    <div class="w-full h-full bg-white dark:bg-black rounded-lg shadow" />
                  </div>
                </div>

                <table class="relative w-full">
                  <caption class="sr-only">
                    Features
                  </caption>
                  <thead>
                  <tr class="text-left">
                    <th scope="col">
                      <span class="sr-only">Feature</span>
                    </th>
                    <th v-for="plan in plans" :key="plan.name" scope="col">
                      <span class="sr-only">{{ plan.name }} plan</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-100 dark:divide-gray-800">
                  <tr v-for="feature in features" :key="feature.title">
                    <th scope="row" class="w-1/4 py-3 pr-4 text-left text-sm font-medium text-gray-600 dark:text-gray-400">{{ feature.title }}</th>
                    <td v-for="(tier, tierIdx) in feature.tiers" :key="tier.title" :class="[tierIdx === feature.tiers.length - 1 ? 'pl-4' : 'px-4', 'relative w-1/4 py-0 text-center']">
                  <span class="relative w-full h-full py-3">
                    <span v-if="typeof tier.value === 'string'" :class="[tier.featured ? 'text-green-500' : 'text-gray-900 dark:text-gray-300', 'text-sm font-medium']">{{ tier.value }}</span>
                    <template v-else>
                      <CheckIcon v-if="tier.value === true" class="mx-auto h-5 w-5 text-green-500" aria-hidden="true" />
                      <XIcon v-else class="mx-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                      <span class="sr-only">{{ tier.value === true ? 'Yes' : 'No' }}</span>
                    </template>
                  </span>
                    </td>
                  </tr>
                  </tbody>
                </table>

                <!-- Fake card borders -->
                <div class="absolute inset-0 flex items-stretch pointer-events-none" aria-hidden="true">
                  <div class="w-1/4 pr-4" />
                  <div class="w-1/4 px-4">
                    <div class="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
                  </div>
                  <div class="w-1/4 px-4">
                    <div class="w-full h-full rounded-lg ring-2 ring-black ring-opacity-5" />
                  </div>
                  <div class="w-1/4 pl-4">
                    <div class="w-full h-full rounded-lg ring-1 ring-black ring-opacity-5" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon, XIcon } from '@heroicons/vue/solid'

const plans = [
  {
    name: 'Free',
    href: 'https://app.interimapi.com/register',
    event: 'KBWE4YRE',
    priceMonthly: 0,
    priceYearly: 0,
    description: 'Individuals and quick projects',
    features: [
      'Personal Workspace',
      'Unlimited Endpoints',
      '100 Records',
      '30 Requests/min',
    ],
    featured: false,
  },
  {
    name: 'Basic',
    href: 'https://app.interimapi.com/register',
    event: ' FF80CQH3 ',
    priceMonthly: 5,
    priceYearly: 50,
    description: 'Power users and small projects',
    features: [
      'Personal Workspace',
      'Unlimited Endpoints',
      '1,000 Records',
      '60 Requests/min',
    ],
    featured: false,
  },
  {
    name: 'Plus',
    href: 'https://app.interimapi.com/register',
    event: ' FY4QACUR ',
    priceMonthly: 10,
    priceYearly: 100,
    description: 'Pro users and large projects',
    features: [
      'Shared Workspaces',
      'Unlimited Endpoints',
      '10,000 Records',
      '120 Requests/min',
    ],
    featured: false,
  },
]

const features = [
  {
    title: 'Personal Workspace',
    tiers: [
      { title: 'free', value: true },
      { title: 'basic', value: true },
      { title: 'plus', value: true },
    ],
  },
  {
    title: 'Shared Workspaces',
    tiers: [
      { title: 'free', value: false },
      { title: 'basic', value: false },
      { title: 'plus', value: 'Unlimited' },
    ],
  },
  {
    title: 'Collaborators',
    tiers: [
      { title: 'free', value: false },
      { title: 'basic', value: false },
      { title: 'plus', value: 'Unlimited' },
    ],
  },
  {
    title: 'API Endpoints',
    tiers: [
      { title: 'free', value: 'Unlimited' },
      { title: 'basic', value: 'Unlimited' },
      { title: 'plus', value: 'Unlimited' },
    ],
  },
  {
    title: 'Database Records',
    tiers: [
      { title: 'free', value: '100' },
      { title: 'basic', value: '1,000' },
      { title: 'plus', value: '10,000' },
    ],
  },
  {
    title: 'RPM per Endpoint',
    tiers: [
      { title: 'free', value: '30' },
      { title: 'basic', value: '60' },
      { title: 'plus', value: '120' },
    ],
  },
  // {
  //   title: 'SQL Dump',
  //   tiers: [
  //     { title: 'free', value: true },
  //     { title: 'basic', value: true },
  //     { title: 'plus', value: true },
  //   ],
  // },
  // {
  //   title: 'CSV Data Import',
  //   tiers: [
  //     { title: 'free', value: false },
  //     { title: 'basic', value: true },
  //     { title: 'plus', value: true },
  //   ],
  // },
  {
    title: 'Customer Support',
    tiers: [
      { title: 'free', value: 'Community' },
      { title: 'basic', value: 'Community' },
      { title: 'plus', value: 'Community & Email' },
    ],
  },
  // {
  //   title: 'Database Storage',
  //   tiers: [
  //     { title: 'free', value: '25MB' },
  //     { title: 'basic', featured: true, value: '125MB' },
  //     { title: 'plus', value: '200' },
  //   ],
  // },
  // {
  //   title: 'Endpoint Restoration',
  //   tiers: [
  //     { title: 'free', value: '3 days' },
  //     { title: 'basic', featured: true, value: '3 days' },
  //     { title: 'plus', value: '7 days' },
  //   ],
  // },
  // {
  //   title: 'Version History',
  //   tiers: [
  //     { title: 'essential', value: 'Coming soon' },
  //     { title: 'plus', featured: true, value: 'Coming soon' },
  //     // { title: 'pro', value: 'Coming soon' },
  //   ],
  // },
]

export default {
  name: "Pricing",
  components: {
    CheckIcon,
    XIcon,
  },
  data() {
    return {
      plans: plans,
      features: features,
      pricing: 'yearly',
    }
  },
  methods: {
    trackGoal(event) {
      window.fathom.trackGoal(event, 0);
    },
  }
}
</script>