<template>
  <div id="faqs" class="scroll-smooth bg-white dark:bg-black">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div class="max-w-3xl mx-auto">
        <h2 class="text-center text-3xl font-extrabold text-gray-900 dark:text-blue-600 sm:text-4xl">Answers to common questions</h2>
        <dl class="mt-12 space-y-6">
          <Disclosure as="div" v-for="faq in faqs" :key="faq.question" v-slot="{ open }">
            <dt class="text-lg rounded-lg border border-gray-100 dark:border-gray-800 bg-gray-50 dark:bg-gray-900 px-4 py-3">
              <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                <span class="text-gray-900 dark:text-gray-400">
                  {{ faq.question }}
                </span>
                <span class="ml-6 h-7 flex items-center">
                  <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 p-4">
              <p class="text-base text-gray-500" v-html="faq.answer">
              </p>
            </DisclosurePanel>
          </Disclosure>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
  import { ChevronDownIcon } from '@heroicons/vue/outline'

  const faqs = [
    {
      id: 1,
      question: "What is a Workspace?",
      answer:
          `A Workspace represents a database in your account. A new database is created for each of your
          Workspaces and each Endpoint in a Workspace represents a table in that database. Endpoints within
          a Workspace have to be unique.`,
    },
    {
      id: 2,
      question: "What is an Endpoint?",
      answer:
          `An Endpoint represents a table in your Workspace's database. When you create an Endpoint, a table is added
          to that database, it's populated with fake data, and an API endpoint is created. Endpoints within a Workspace
          have to be unique.`,
    },
    {
      id: 3,
      question: "What is an Attribute",
      answer:
          `When you create an Endpoint, which represents a table, you add Attributes which represent the table columns.
          For each Attribute, you assign a Data Generator that will generate the type of data you want populated in that
          column within your Endpoint's table.`,
    },
    {
      id: 4,
      question: "What is a Generator?",
      answer:
          `When you add an Attribute to your Endpoint, you choose a Generator for the type of data you want populated
           within the Attribute's column in the database table.`,
    },
    {
      id: 5,
      question: "Do you offer discounts?",
      answer:
          `We occasionally offer promotional discounts. You can follow us on Twitter (<a href="https://twitter.com/InterimAPI" class="text-blue-600" target="_blank">@InterimAPI</a>) and sign up below for
          our occasional email updates to get notified.`,
    },
  ]

  export default {
    name: 'Faqs',
    components: {
      Disclosure,
      DisclosureButton,
      DisclosurePanel,
      ChevronDownIcon,
    },
    data() {
      return {
        faqs: faqs
      }
    }
  }
</script>