export const fakers = new Map([
    ["Numbers & Text", {
        "randomDigit": {
            label: "Digit",
            example: "6",
            description: "A random integer between 0 and 9",
            enabled: true,
            type: "integer"
        },
        "randomNumber": {
            label: "Number",
            example: "19238",
            description: "A random integer, containing between 1 and 5 digits",
            enabled: true,
            type: "integer"
        },
        "randomFloat": {
            label: "Float",
            example: "43.23",
            description: "A random float with 2 decimals",
            enabled: true,
            type: "decimal"
        },
        "randomLetter": {
            label: "Letter",
            example: "h",
            description: "A random character from the alphabet",
            enabled: true,
            type: "string"
        },
        "word": {
            label: "Word",
            example: "distinctio",
            description: "A random string containing a word",
            enabled: true,
            type: "string"
        },
        "phrase": {
            label: "Phrase",
            example: "Sit non vitae voluptas",
            description: "A random phrase containing 4 words",
            enabled: true,
            type: "string"
        },
        "sentence": {
            label: "Sentence",
            example: "Sit non vitae voluptas sint non voluptates laboriosam non voluptas.",
            description: "A random sentence containing 10 words",
            enabled: true,
            type: "string"
        },
        "paragraph": {
            label: "Paragraph",
            example: "Similique molestias exercitationem officia aut. Itaque doloribus et rerum voluptate iure. Unde veniam magni dignissimos expedita eius.",
            description: "A random paragraph of text containing 3 sentences",
            enabled: true,
            type: "string"
        },
        "text": {
            label: "Text",
            example: "Quaerat eveniet magni a optio. Officia facilis cupiditate fugiat earum ipsam nemo nulla...",
            description: "A random string of text with 200 words",
            enabled: true,
            type: "string"
        },
        "realText": {
            label: "Real Text",
            example: "VERY short remarks, and she ran across the garden, and I had not long to doubt, for the end of the bottle was NOT marked 'poison,' it is right?' 'In my youth,' Father William replied to his ear.",
            description: "A random string of real text with 200 characters",
            enabled: true,
            type: "string"
        },
    }],

    ["Persona", {
        "safeEmail": {
            label: "Email",
            example: "wolf.sabryna@example.org",
            description: "A random safe email address",
            enabled: true,
            type: "string"
        },
        "userName": {
            label: "Username",
            example: "ipaucek",
            description: "A random username",
            enabled: true,
            type: "string"
        },
        "password": {
            label: "Password",
            example: "dE1U[G$n4g%-Eie[]rn[",
            description: "A random password between 8-20 characters",
            enabled: true,
            type: "string"
        },
        "fullName": {
            label: "Full Name",
            example: "Rachel Zulauf",
            description: "A random person's full name",
            enabled: true,
            type: "string"
        },
        "firstName": {
            label: "First Name",
            example: "Rachel",
            description: "A random person's first name",
            enabled: true,
            type: "string"
        },
        "lastName": {
            label: "Last Name",
            example: "Zulauf",
            description: "A random person's last name",
            enabled: true,
            type: "string"
        },
    }],

    ["Location", {
        "address": {
            label: "Full Address",
            example: "8888 Cummings Vista Apt. 101, Susanbury, NY 95473",
            description: "A random full U.S. address",
            enabled: true,
            type: "string"
        },
        "streetAddress": {
            label: "Street Address",
            example: "439 Karley Loaf Suite 897",
            description: "A random U.S. street address",
            enabled: true,
            type: "string"
        },
        "city": {
            label: "City",
            example: "West Judge",
            description: "A random U.S. city name",
            enabled: true,
            type: "string"
        },
        "state": {
            label: "State",
            example: "Texas",
            description: "A random U.S. state name",
            enabled: true,
            type: "string"
        },
        "phoneNumber": {
            label: "Phone",
            example: "827-986-5852",
            description: "A random U.S. phone number",
            enabled: true,
            type: "string"
        },
        "postcode": {
            label: "Post Code",
            example: "17916",
            description: "A random U.S. postal code",
            enabled: true,
            type: "string"
        },
        "countryCode": {
            label: "Country Code",
            example: "US",
            description: "A random 2-letter country code string",
            enabled: true,
            type: "string"
        },
        "languageCode": {
            label: "Language Code",
            example: "en",
            description: "A random 2-letter language code string",
            enabled: true,
            type: "string"
        },
    }],

    ["Finance", {
        "currencyCode": {
            label: "Currency Code",
            example: "USD",
            description: "A random currency code string",
            enabled: true,
            type: "string"
        },
        "creditCardType": {
            label: "Credit Card Type",
            example: "Visa",
            description: "A random credit card type",
            enabled: true,
            type: "string"
        },
        "creditCardNumber": {
            label: "Credit Card Number",
            example: "4539710900519030",
            description: "A random credit card number",
            enabled: true,
            type: "string"
        },
        "creditCardExpirationDateString": {
            label: "Credit Card Expiration",
            example: "09/23",
            description: "A random valid expiration date string",
            enabled: true,
            type: "string"
        },
        "lowPrice": {
            label: "Low Price",
            example: "7.20",
            description: "A random float between 1 and 10",
            enabled: true,
            type: "integer"
        },
        "midPrice": {
            label: "Mid Price",
            example: "72.00",
            description: "A random float between 10 and 100",
            enabled: true,
            type: "integer"
        },
        "highPrice": {
            label: "High Price",
            example: "720.00",
            description: "A random float between 100 and 1000",
            enabled: true,
            type: "integer"
        },
    }],

    ["Date & Time", {
        "unixTime": {
            label: "Unix Time",
            example: "1605544623",
            description: "A random unix time between 0 and NOW",
            enabled: true,
            type: "integer"
    },
        "dateTime": {
            label: "DateTime",
            example: "2021-12-28 12:18:37",
            description: "A random DateTime between Jan 1, 1970 and NOW",
            enabled: true,
            type: "string"
    },
        "iso8601": {
            label: "ISO8601",
            example: "2015-10-05T13:43:19+0000",
            description: "A random ISO8601 formatted string",
            enabled: true,
            type: "string"
    },
        "date": {
            label: "Date String",
            example: "1999-06-09",
            description: "A random date string in the format 'Y-m-d'",
            enabled: true,
            type: "string"
    },
        "time": {
            label: "Time String",
            example: "12:02:50",
            description: "A random time string in the format 'H:i:s'",
            enabled: true,
            type: "string"
    },
        "timezone": {
            label: "Timezone",
            example: "America/Chicago",
            description: "A random timezone name",
            enabled: true,
            type: "string"
    },
}],

    ["Files", {
        "txtFileName": {
            label: "TXT File",
            example: "https://cdn.interimapi.com/public/file-samples/document.txt",
            description: "A txt file url",
            enabled: true,
            type: "string"
        },
        "pdfFileName": {
            label: "PDF File",
            example: "https://cdn.interimapi.com/public/file-samples/document.pdf",
            description: "A pdf file url",
            enabled: true,
            type: "string"
        },
        "mp4FileName": {
            label: "MP4 File",
            example: "https://cdn.interimapi.com/public/file-samples/video.mp4",
            description: "A mp4 file url",
            enabled: true,
            type: "string"
        },
        "mp3FileName": {
            label: "MP3 File",
            example: "https://cdn.interimapi.com/public/file-samples/audio.mp3",
            description: "A mp3 file url",
            enabled: true,
            type: "string"
        },
        "jpgFileName": {
            label: "JPG File",
            example: "https://cdn.interimapi.com/public/file-samples/image.jpg",
            description: "A jpg file url",
            enabled: true,
            type: "string"
        },
        "pngFileName": {
            label: "PNG File",
            example: "https://cdn.interimapi.com/public/file-samples/image.png",
            description: "A png file url",
            enabled: true,
            type: "string"
        },
        "svgFileName": {
            label: "SVG File",
            example: "https://cdn.interimapi.com/public/file-samples/image.svg",
            description: "A svg file url",
            enabled: true,
            type: "string"
        },
        "imageUrl": {
            label: "Placeholder Image",
            example: "https://via.placeholder.com/640x480.png/004466?text=animals+omnis",
            description: "An random image url from placeholder.com",
            enabled: true,
            type: "string"
        },
    }],

    ["Internet", {
        "html": {
            label: "HTML",
            example: "<html><head><title>Architecto ut eius nisi molestiae atque ab.</title></head><body><form action='example.net' method='POST'><label for='username'>saepe</label><input type='text' id='username'><label for='password'>est</label><input type='password' id='password'></form></body></html>",
            description: "A random HTML string",
            enabled: true,
            type: "string"
        },
        "json": {
            label: 'JSON',
            example: '{"red":"#ff0000", "blue":"#0065ff"}',
            description: "A random JSON string",
            enabled: true,
            type: "string"
        },
        "safeEmailDomain": {
            label: "Domain",
            example: "example.org",
            description: "A random safe domain",
            enabled: true,
            type: "string"
        },
        "url": {
            label: "Url",
            example: "http://duckduckgo.com",
            description: "A random url",
            enabled: true,
            type: "string"
        },
        "slug": {
            label: "Slug",
            example: "ipsa-consectetur-est",
            description: "A random slug",
            enabled: true,
            type: "string"
        },
        "ipv4": {
            label: "ipv4",
            example: "90.119.172.201",
            description: "A random  IPv4 address",
            enabled: true,
            type: "string"
        },
        "macAddress": {
            label: "Mac Address",
            example: "94:00:10:01:58:07",
            description: "A random MAC address",
            enabled: true,
            type: "string"
        },
        "userAgent": {
            label: "User Agent",
            example: "Mozilla/5.0 (X11; Linux x86_64)",
            description: "A random user agent",
            enabled: true,
            type: "string"
        },
    }],

    ["Business", {
        "company": {
            label: "Company",
            example: "Bogan-Treutel",
            description: "A random company name",
            enabled: true,
            type: "string"
        },
            "catchPhrase": {
            label: "Slogan",
            example: "Monitored regional contingency",
            description: "A random company slogan/catchphrase",
            enabled: true,
            type: "string"
        },
            "bs": {
            label: "Jargon",
            example: "e-enable robust architectures",
            description: "Random company bullshit",
            enabled: true,
            type: "string"
        },
            "jobTitle": {
            label: "Job Title",
            example: "Cashier",
            description: "A random job title",
            enabled: true,
            type: "string"
        },
    }],

    ["Utility", {
        "boolean": {
            label: "Boolean",
            example: "true",
            description: "A random boolean value",
            enabled: true,
            type: "boolean"
        },
        "hexColor": {
            label: "Hex Color",
            example: "#ccd578",
            description: "A random hex color string",
            enabled: true,
            type: "string"
        },
        "rgbCssColor": {
            label: "RGB Color",
            example: "rgb(9,110,101)",
            description: "A random css-friendly RGB color string",
            enabled: true,
            type: "string"
        },
        "emoji": {
            label: "Emoji",
            example: "🤪",
            description: "A random emoji",
            enabled: true,
            type: "string"
        },
        "uuid": {
            label: "UUID",
            example: "bf91c434-dcf3-3a4c-b49a-12e0944ef1e2",
            description: "A random UUID",
            enabled: true,
            type: "string"
        },
        "md5": {
            label: "MD5",
            example: "b1f447c2ee6029c7d2d8b3112ecfb160",
            description: "A random MD5 hash",
            enabled: true,
            type: "string"
        },
        "sha1": {
            label: "SHA-1",
            example: "20d1061c44ca4eef07e8d129c7000101b3e872af",
            description: "A random SHA-1 hash",
            enabled: true,
            type: "string"
        },
        "sha256": {
            label: "SHA-256",
            example: "bfa80759a5c40a8dd6694a3752bac231ae49c136396427815b0e33bd10974919",
            description: "A random SHA-256 hash",
            enabled: true,
            type: "string"
        },
        "randomForeignKey": {
            label: "Foreign Key",
            example: "6",
            description: "A random integer between 1 and 50. A simple way to generate a foreign key until better relationship functionality is implemented. Only works with tables that have id set to auto-increment.",
            enabled: true,
            type: "integer"
        },
    }]

    //["randomId", {label: "ID", example: "1440", description: "A random integer between 1 and 10000", enabled: true, type: "integer"}],
    //["numerify", {label: "Numerify", example: "", description: ""}],
    //["lexify", {label: "Lexify", example: "", description: ""}],
])
